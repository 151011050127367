import React from "react";
import { Link } from "gatsby";

const Logo = ({ image }) => {
  return (
    <div className="logo">
      <Link to="/">
        <img
          src={image}
          alt="Logo showing the letters k and b with an orange dot at the end"
          className="logo primary-logo transform-scale-h"
        />
      </Link>
    </div>
  );
};

export default Logo;
