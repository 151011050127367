import React from "react";
import FooterMenu from "../footer/FooterMenu";
import { Link } from "gatsby";

const Footer = ({ showSocials = true }) => {
  return (
    <footer id="footer" className="site-footer">
      <div className="wrapper no-space">
        <div className="row">
          <div className="col col-xl-12 col-lg-12 align-self-center">
            <div className="menu float-lg-left float-md-left">
              <nav className="menu-secondary menu-secondary-small">
                <ul className="clearfix list-unstyled">
                  <li>
                    <Link
                      to="/imprint"
                      className="btn btn-link transform-scale-h border-0 p-0"
                    >
                      Imprint
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy"
                      className="btn btn-link transform-scale-h border-0 p-0"
                    >
                      Privacy
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="menu float-lg-right float-md-right">
              {showSocials ? <FooterMenu /> : ""}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
