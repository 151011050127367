import React, { Component } from "react";
import { Link } from "gatsby";
import { Modal } from "react-bootstrap";
import FooterMenu from "../../blocks/footer/FooterMenu";
import MyLink from "../../blocks/link/MyLink";
import LogoImage2 from "../../assets/img/logo/logo_kristinbaumann_lightblue_dots.svg";
import { useGoal } from "gatsby-plugin-fathom";

class MenuModal extends Component {
  constructor(context) {
    super(context);

    this.state = {
      showModal: false,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }

  doSomethingBeforeOpen() {
    this.open();
  }

  render() {
    const useHandleGoal = (goalId) => useGoal(goalId);

    const menuItemsData = [
      // {
      //   pathname: "/",
      //   label: "Home",
      //   goalId: "SCTNPIBY",
      // },
      {
        pathname: "/about",
        label: "About",
        goalId: "JBPDNNSK",
      },
      {
        pathname: "/projects",
        label: "Projects",
        goalId: "NSSCSJKC",
      },
      // {
      //   pathname: "https://blog.kristin-baumann.com",
      //   label: "Blog",
      // },
      {
        pathname: "/contact",
        label: "Contact",
        goalId: "6TMTW2WY",
      },
    ];
    return (
      <div className="menu-toggle align-self-center no-space">
        <nav className="menu-tertiary">
          <ul>
            {menuItemsData.map((item) => (
              <li className="menu-item align-self-center" key={item.label}>
                <MyLink
                  to={item.pathname}
                  activeClassName="active"
                  className="btn btn-link border-0 p-0 transform-scale-h"
                  onClick={() => useHandleGoal(item.goalId)}
                >
                  <span className="align-middle">
                    <mark>{item.label} </mark>
                  </span>
                </MyLink>
              </li>
            ))}
          </ul>
        </nav>
        <button
          onClick={this.doSomethingBeforeOpen.bind(this)}
          type="button"
          className="btn btn-link border-0 p-0 transform-scale-h"
          data-toggle="modal"
          data-target="#menu-modal"
          id="menu-toggle-button"
        >
          <span className="align-middle">Menu</span>
          <i className="icon-c icon-menu" />
        </button>

        <Modal
          className="modal modal-adv-1 modal-body-pr fade"
          id="menu-modal"
          show={this.state.showModal}
          onHide={this.close}
          backdrop={false}
          aria-labelledby="menu-modal"
          aria-hidden="true"
          dialogClassName="modal-full wrapper h-100"
          ref={(node) => (this.chart = node)}
        >
          <Modal.Header>
            <Link to="/">
              <img
                className="logo logo-secondary transform-scale-h"
                src={LogoImage2}
                alt="Logo"
              />
            </Link>

            <button
              onClick={this.close}
              type="button"
              className="close btn btn-link border-0 transform-scale-h"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style={{ whiteSpace: "nowrap" }}>
                <span className="align-middle">Close</span>
                <i className="icon-c icon-close" />
              </span>
            </button>
          </Modal.Header>

          <Modal.Body className="d-flex justify-content-between">
            <div className="align-self-end">
              <FooterMenu />
            </div>

            <div className="align-self-center animated fadeinright w-100">
              <nav className="menu-primary">
                <ul className="clearfix list-unstyled">
                  {menuItemsData.map((item) => (
                    <li className="menu-item" key={item.label}>
                      <MyLink
                        to={item.pathname}
                        activeClassName="active"
                        onClick={() => useHandleGoal(item.goalId)}
                      >
                        {item.label}
                      </MyLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default MenuModal;
