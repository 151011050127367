import React, { Fragment } from "react";
import "../assets/sass/style.scss";

import Seo from "../blocks/seo/Seo";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleGeneric from "../blocks/page-title/PageTitleGeneric";
// import ContactForm from "../components/form/ContactForm";
import dataFooterMenu from "../data/footer/footerMenu";
import { useGoal } from "gatsby-plugin-fathom";

const Contact = () => {
  const handleGoal = useGoal("Q37L4TRN");

  return (
    <Fragment>
      <Seo
        title="Contact"
        keywords="contact keywords"
        bodyAttributes={{
          class: "page contact-page bg-fixed bg-line",
        }}
      />

      <Header />

      <main id="main" className="site-main">
        <PageTitleGeneric title="Contact" />

        <section id="page-content" className="block spacer p-top-xl">
          <div className="wrapper">
            <div id="contacts" className="block">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-4 col-12">
                  <h2>
                    Let's work <span className="line">together</span>
                  </h2>

                  <p className="mt-0">
                    New project idea? Freelance inquiry? Or just up for a chat?
                    Get in touch!
                  </p>
                </div>

                <div className="col-xl-7 col-lg-7 col-md-8 col-12">
                  <div className="list-group list-group-horizontal-sm">
                    <div className="list-group-item">
                      <h4>Write an email</h4>
                      <span
                        title="hello@kristin-baumann.com"
                        href="mailto:hello@kristin-baumann.com"
                        className="btn btn-link border-0 p-0"
                        style={{
                          textAlign: "left",
                          marginBottom: "1.5rem",
                          userSelect: "all",
                          cursor: "default",
                          textTransform: "lowercase",
                        }}
                      >
                        hello@kristin-baumann.com
                      </span>
                      <a
                        href="mailto:hello@kristin-baumann.com"
                        className="btn btn-dark transform-scale-h"
                        onClick={handleGoal}
                      >
                        Send me an email
                      </a>
                    </div>
                  </div>
                  <div className="list-group list-group-horizontal-sm mt-0">
                    <div className="list-group-item">
                      <h4>Or connect with me here</h4>
                      <ul
                        className="list-unstyled"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          columnGap: "1rem",
                        }}
                      >
                        {dataFooterMenu &&
                          dataFooterMenu.map((item, key) => {
                            return (
                              <li key={key}>
                                <a
                                  key={item.title}
                                  title={item.title}
                                  className="btn btn-link transform-scale-h"
                                  href={item.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    paddingLeft: "0",
                                    paddingRight: "0",
                                  }}
                                >
                                  {item.title}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="block spacer p-top-xl">
              <h2>
                Fill up a <span className="line">form</span>
              </h2>

              <ContactForm />
            </div> */}
          </div>
        </section>
      </main>

      <Footer showSocials={false} />
    </Fragment>
  );
};

export default Contact;
